import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const ProductContainer = (data) => {
  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((item, index) => {
            let data = item.node.data;

            let description = data.product_description;

            if (description != null) {
              description = description.substr(0, 200);
            }

            let lazyLoadImg;

            if (data.product_image == null) {
              return;
            }
            if (index > 3) {
              lazyLoadImg = (
                <img
                  fetchpirority="low"
                  loading="lazy"
                  className="rounded-xl mb-1 pr-0"
                  src={data.product_image}
                  alt={`Collective Dress ${data.product_title
                    .split(/\b/)
                    .reverse()
                    .join("")}`}
                />
              );
            } else {
              lazyLoadImg = (
                <img
                  className="rounded-xl mb-1 pr-0"
                  src={data.product_image}
                  alt={`Collective Dress ${data.product_title
                    .split(/\b/)
                    .reverse()
                    .join("")}`}
                />
              );
            }

            return (
              <div className="product-wrapper px-2">
                <a
                  target="_blank"
                  href={`/products/cd-${data.product_title.trim().toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {lazyLoadImg}
                </a>
                <a
                  target="_blank"
                  className="product-link"
                  href={`/products/cd-${data.product_title.trim().toLowerCase().replace(/\s+/g, "-")}`}
                >
                  <h2 className="product-title md:text-base lg:text-xl font-extrabold">
                    CD-{data.product_title.split(/\b/).reverse().join("")}
                  </h2>

                  {/*  reverse the tile .split(/\b/).reverse().join('') */}
                </a>

                {/* <span>{data.company}</span> */}
                <p className="mb-2">{data.product_price}</p>
                <p className="product-description md:text-sm max-w-md">
                  {description}
                </p>
              </div>
            );
          })}
      </>
    );
  }  

  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = data.edges.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.edges.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.edges.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`,
      );
      setItemOffset(newOffset);
      var element_to_scroll_to = document.querySelectorAll('.descriptions-container')[0]
      element_to_scroll_to.scrollIntoView();
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel=".."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num pagination-pre"
          nextLinkClassName="page-num paingation-next"
          activeLinkClassName="active"
        />
      </>
    );
  }

  return (
    <>
      <PaginatedItems itemsPerPage={24} />
    </>
  );
};

export default ProductContainer;
